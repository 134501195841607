import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/login.css";
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import TextInput from './Common/TextInput'
import LoginDataService from "../services/login.service";
import Botao from './Common/Botao'
import { Link } from 'react-router-dom';
import { isLogin } from '../utils';
export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ErrorMessage: ""

    }
  }
  handleSubmit(event) {
    var $ = require("jquery");
    event.preventDefault();

    let DadosLogin = JSON.stringify({
      'password': event.target.Senha.value,
      'username': event.target.Email.value,
      'painelAdmin': true
      // 'UserAgent': browser.userAgent,
      // 'ip': ip.address()
    });
    LoginDataService.signin(DadosLogin
    ).then(res => {
      if ((res.status === 200) && (res.data.roles[0] === 'ROLE_ADMIN')) {
        console.log(res.data);
        localStorage.setItem('Id', res.data.id);
        localStorage.setItem('Email', res.data.email);
        localStorage.setItem('Role', res.data.roles[0]);
        localStorage.setItem('Token', res.data.accessToken);
        if (isLogin()) {
          window.location = "/lancamentos";
        }
        else {
          window.location = "/login";
        }
      }
      else {
        $(".validation").removeClass("d-none")
        $(".message").text('Login não autorizado');
      }
    }).catch(function (error) {
      let message = 'Ocorreu um erro inesperado.';
      if (error.response !== undefined) {
        message = error.response.data.message;
      }
      else
        if (error.message !== undefined) {
          message = error.message;
        }
      $(".validation").removeClass("d-none")
      $(".message").text(message);
      // this.setState({ ErrorMessage: message });
    });



  }
  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Login" ></Header>
        <form className="p-0 m-0" onSubmit={this.handleSubmit}>
          <Container fluid={true} className="mt-2 pt-5 pb-5 login">
            <Row className=" pt-0 mt-0">
              <Col>
                <Container>
                  <Row>
                    <Col></Col>
                    <Col sm={12} xs={12} md={10} lg={7} className="mx-auto px-auto ">
                      <div className="card card-login my-4 py-4">
                        <div className="card-body">
                          <Container>
                            <Row className="form-group">
                              <Col className="px-auto " xs={12}>
                                <h2>
                                  Login
                                </h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} >
                                <TextInput
                                  type="text"
                                  className="w-100 px-2"
                                  placeholder="Email:"
                                  id="Email"
                                  name="Email"
                                  onChange={this.onChangeText} />
                              </Col>
                            </Row>
                            <Row className="mb-0 pb-0">
                              <Col xs={12} >
                                <TextInput
                                  type="password"
                                  className="w-100 px-2 mb-0 pb-0"
                                  formGroupClass="mb-0"
                                  placeholder="Senha:"
                                  id="Senha"
                                  name="Senha"
                                  onChange={this.onChangeText} />
                              </Col>
                            </Row>
                            <Row className="pt-0 mt-0">
                              <Col xs={6} className="text-left">
                                <Link to="perdeu-senha">Perdeu a senha?</Link>
                              </Col>
                              <Col xs={6} className="text-right">
                                {/* <Link to="cadastre">Ainda não sou assinante</Link> */}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={0} className="text-left">
                                {/* <CheckBox
                            id="LembrarMe"
                            name="LembrarMe"
                            value="Lembrar-me"
                            className="me-3"
                          /> */}
                                <p className="validation d-none"><i className="fa fa-exclamation-circle px-1" /><span className="message" /> </p>
                              </Col>
                              <Col xs={12} className="text-right">
                                <Botao className="btn btn-primary float-lg-end px-5" submit={"true"} value={"ENTRAR"}></Botao>
                              </Col>
                            </Row>
                          </Container>

                        </div>
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>
                </Container>
              </Col>

            </Row>
          </Container>
        </form>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}
