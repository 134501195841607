import React, { PureComponent } from 'react';
import categoriaDataService from "../services/categoria.service";
import saldoDataService from "../services/saldo.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter,  getReferenciaMonth, getReferenciaYear } from '../utils';


const handleButtonClick = (state) => {
  window.location = "/editar-categoria";
};

class categorias extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categorias: [],
      saldos: [],
      total: 0,
      totalpago: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),

      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando categorias"
    };

  }

  handleLogout = () => {

  }

  nextDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
    if (pDay !== null) {
      this.setState({
        DiaPrincipal: newDay
      })
    }

    this.onClickDay(newDay);
  }
  handlecategoriaClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-categoria/" + index;
  }
  handlecategoriaRelatorioClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/categoria/"+index+"/relatorio" ;
  }

  handleSaldoClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-saldo/" + index;
  }
  handleNovocategoria = () => {
    window.location = APP_URL() + "categoria/novo";
  }
  clickDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = new Date(pDay);

    if ((pDay !== null)) {
      this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
      if (this.state.filtro !== 'semanal') {
        this.setState({
          DiaPrincipal: newDay
        })
      }
    }
  }

  onClickDay(event) {
    this.filtraAgenda('01', event.getMonth(), event.getFullYear());
    // localStorage.setItem('Referencia-day', event.getDate());
    localStorage.setItem('Referencia-month', event.getMonth());
    localStorage.setItem('Referencia-year', event.getFullYear());
  }

  filtraAgenda(day, month, year) {
    let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;

    this.setState({
      AgendaFiltro: this.state.AgendaMes.filter(
        filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
          && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
          && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
      DiaFiltro: pDiaFiltro
    });

    if (this.state.filtro !== 'semanal') {
      this.setState({
        DiaPrincipal: pDiaFiltro
      })
    }
    if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

      this.setState({
        AgendaMes: [],
        mark: []

      })
      this.getcategorias(pDiaFiltro);
      this.getsaldos(pDiaFiltro);
    }
  }

  setFiltro = filter => {
    this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

  }
  componentDidMount() {
    this.getcategorias();
  }
  calcularTotais() {
    var _total = 0.00;
    var _totalpago = 0;
    var _totalpendente = 0;
    this.state.categorias.forEach(element => {
      _total += parseFloat(element.valor)
      _totalpago += element.valorpago === null ? 0 : parseFloat(element.valorpago)
      _totalpendente += parseFloat(element.valorpago > 0 ? 0 : element.valor)
    });
    this.setState({ total: _total, totalpago: _totalpago, totalpendente: _totalpendente })
  }


  getcategorias(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    categoriaDataService.getAll()
      .then(response => {
        this.setState({
          categorias: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });

      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (
 
      <div className="page-wrapper" >

        <NavTop></NavTop>
        <NavMain></NavMain>
        {/* <button onClick={notify}>Notify!</button> */}
        <Header title="contas..." fluid={true}></Header>
        <section className="course-one course-one__teacher-details home-one">

 
            <Container fluid={false}>
              <Row className={"float-end"}>
                <Col className={"py-2 "}>
                  <Link to="/editar-categoria" className="btn float-end btn-info botao-acao" >+</Link>
                </Col>
              </Row>
              <Row className={""}>

                <Col>


                  <Table striped bordered hover size="sm" >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Categoria</th>
                        <th>Relatório</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.categorias.map(data =>

                          <tr key={data.id}>
                            <td><Link onClick={() => this.handlecategoriaClick(data.id)}> {data.id}</Link></td>
                            <td>{data.descricao}</td>

                            <td><span className={"text-right d-block text-info"} ><Link onClick={() => this.handlecategoriaRelatorioClick(data.id)}> Ver</Link></span></td>


                          </tr>

                        )
                      }
                    </tbody>

                  </Table>
                </Col>
              </Row>
            </Container>
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>      
      </div >
    );
  }
}
export default categorias;
