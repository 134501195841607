
import React, { Component/*, NavLink*/ } from 'react'
// import Botao from '../components/inputs/Botao';
import { Row, Col, Container } from 'react-bootstrap';
import Botao from './Common/Botao';
class Feedback extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fecharDiv: false
        }
    }


    render() {
        //let valor = "";
        return (

                <Container fluid={true} className={this.props.className}>
                    <Row>
                        <Col>
                            <h3 className="feedback-titulo"><b>{this.props.titulo}</b></h3>
                            <br />
                            <p className="feedback-texto">{this.props.mensagem}</p>
                        </Col>
                    </Row>
                    {this.props.linkEntendi || this.props.textoLink !== "" ?
                        <Row>
                            <Col sm="12">
                                <Botao className="btn botao-feedback"
                                    value={this.props.textoLink !== "" ? this.props.textoLink : "Entendi"}
                                    BotaoLink="true"
                                    FunctionClickCall={this.props.handleLink}></Botao>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                    {this.props.handleBotao !== undefined && this.props.textoBotao !== "" ?
                        <Row>
                        <Col sm="12">
                                <Botao className="btn btn-primary me-2 col-12 mt-4"
                                    value={this.props.textoBotao}
                                    BotaoLink="true"
                                    FunctionClickCall={this.props.handleBotao}></Botao>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                    {this.props.handleBotaoUm !== undefined && this.props.textoBotaoUm !== "" &&
                        this.props.handleBotaoDois !== undefined && this.props.textoBotaoDois !== "" ?
                        <Row className='text-center'>
                            <Col xs={6}>
                                <Botao className={"btn float-end " + this.props.ClassNameBotaoUm}
                                    value={this.props.textoBotaoUm}
                                    BotaoLink="true"
                                    with_row="true"
                                    FunctionClickCall={this.props.handleBotaoUm}></Botao>
                            </Col>
                            <Col xs={6}>

                                <Botao className={"btn float-start " + this.props.ClassNameBotaoDois}
                                    value={this.props.textoBotaoDois}
                                    BotaoLink="true"
                                    with_row="true"
                                    FunctionClickCall={this.props.handleBotaoDois}></Botao>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                </Container>

        );
    }
}

export default Feedback;