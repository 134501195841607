import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {
        return (
            <div className="search-popup">
               
            </div>
        );
    }
}
export default Footer;              